import { useDispatch } from "react-redux";
import { setToken } from "../../api/auth/authSlice";
import { Paper, Card, CardHeader, CardContent, Box, Button, Stack, Typography, useTheme } from "@mui/material";

function DevelopmentLoginButtons({onSuccess}) {
    const dispatch = useDispatch();
    const theme = useTheme();

    function loginAs(what) {
        console.log(`Logging in as ${what}`)
        dispatch(setToken(what));
        onSuccess()
    }

    return (
        <Card sx={{width: "350px"}}>
            <CardHeader title={"Developer Login"} />
            <CardContent>
                <Stack direction="row" spacing={1} justifyContent={"center"}>
                    <Button variant="contained" theme={theme} onClick={() => loginAs("applicant")}>Applicant</Button>
                    <Button variant="contained" theme={theme} onClick={() => loginAs("recruiter")}>Recruiter</Button>        
                    <Button variant="contained" theme={theme} onClick={() => loginAs("dev_admin_kalakukko")}>Admin</Button>        
                </Stack>
            </CardContent>
        </Card>
    )
}

export { DevelopmentLoginButtons };