
import { apiSlice } from '../api/apiSlice'

export const adminApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    getStatsByKind: builder.query ({
        query: (kind) => `v2/stats/${kind}`,
        keepUnusedDataFor: 0
    }),
    addPersonPermission: builder.mutation ({
        query: (body) => ({
            url: `v2/personpermission/`,
            method: 'POST',
            body: body
        }),
        invalidatesTags: ["PersonPermission"]
    }),
    removePersonPermission: builder.mutation ({
        query: (body) => ({
            url: `v2/personpermission/`,
            method: 'DELETE',
            body: body
        }),
        invalidatesTags: ["PersonPermission"]
    }),
  })
});

export const {
    useGetStatsByKindQuery,
    useAddPersonPermissionMutation,
    useRemovePersonPermissionMutation
} = adminApi
